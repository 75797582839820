﻿var mayojs = (function() {


    return {
        getDate: function () {
            return new Date();
        },
        set: function (id, content, url) {
            $(id, content).attr({ 'src': url });
            return content;
        },
        init: function () {
            $(document).ready(function () {
                var toggleAffix = function (affixElement, wrapper, scrollElement) {

                    var height = affixElement.outerHeight(),
                        top = wrapper.offset().top;

                    if (scrollElement.scrollTop() >= top) {
                        wrapper.height(height);
                        affixElement.addClass("affix");
                    } else {
                        affixElement.removeClass("affix");
                        wrapper.height('auto');
                    }

                };

                $('#m-slideshow').carousel("cycle");
                $('[data-toggle="affix"]').each(function () {
                    var ele = $(this),
                        wrapper = $('<div></div>');

                    ele.before(wrapper);
                    $(window).on('scroll resize',
                        function () {
                            toggleAffix(ele, wrapper, $(this));
                        });

                    // init
                    toggleAffix(ele, wrapper, $(window));
                });

                // handle ctrl+p event
                $(document).on("keydown", function (e) {
                    if ((e.ctrlKey || e.metaKey) && e.keyCode === 80) {
                        // stop default print
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        handlePrinting();
                    }
                });
            });
        },
        kWidgetEmbedFunc: function (targetId, entryId) {

            kWidget.embed({
                'targetId': targetId,
                'wid': '_1825021',
                'uiconf_id': '42047101',
                'entry_id': entryId,
                'flashvars': { // flashvars allows you to set runtime uiVar configuration overrides. 
                    'autoPlay': false
                },
                'params': { // params allows you to set flash embed params such as wmode, allowFullScreen etc
                    'wmode': 'transparent'
                },
                readyCallback: function (playerId) {
                    console.log('Player:' + playerId + ' is ready ');
                }
            });
        },
        loadAnalyticScript: function(pocId) {
            var isFound = false;
            //debugger;
            var analytics = $("#m-analytics-script").val();
            if (analytics) {
                var scripts = document.getElementsByTagName("script");
                for (var i = 0; i < scripts.length; ++i) {
                    if (scripts[i].getAttribute('src') !== null && scripts[i].getAttribute('src').includes(analytics)) {
                        isFound = true;
                    }
                }
                var subject = $(".composite-pri-subject").map(function () { return this.value; })
                    .get().join('|');
                var focus = $(".composite-focus").map(function () { return this.value; })
                    .get().join('|');
                _dl = {
                    "version": "1.0.0",
                    "pocID": pocId,
                    "subject": subject,
                    "focus": focus, 
                    "clntBizID": "rchynoar-8yTmBCLeWhP3oSQ4CRohEQ1N",
                    "clntBizNm": "CD_WEB"
                };

                if (!isFound) {
                    var dynamicScripts = [analytics];

                    for (var i = 0; i < dynamicScripts.length; i++) {
                        var node = document.createElement('script');
                        node.src = dynamicScripts[i];
                        node.type = 'text/javascript';
                        node.async = false;
                        node.charset = 'utf-8';
                        document.getElementsByTagName('head')[0].appendChild(node);
                    }

                }
            }
        },
        loadTealiumScript: function (isProd) {
            var envString = isProd ? 'prod' : 'dev';

            var headerNode = document.createElement('script');
            headerNode.src = `//tagdelivery.mayoclinic.org/intcareplans/${envString}/utag.sync.js`;
            headerNode.type = 'text/javascript';
            $("head").first().prepend(headerNode);

            var bodyNode = document.createElement('script');
            bodyNode.type = 'text/javascript';
            bodyNode.text = `(function(a,b,c,d) {a='//tagdelivery.mayoclinic.org/intcareplans/${envString}/utag.js ';` +
                            `b=document;c='script';d=b.createElement(c);d.src=a;`+
                            `d.type='text/java'+c;d.async=true;` +
                            `a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a)})();`
            $("body").first().prepend(bodyNode);
        },
        doPrint: function () {      // print button click
            handlePrinting();
        }
    };

})(mayojs || {});

var handlePrinting = function () {
    var userAgent = navigator.userAgent;
    console.log("user agent:  " + navigator.userAgent);
    // only need to do after print event delay for mobile safari browser
    var afterPrintDelay = userAgent.match(/macintosh|iphone|ipad|ipod/gi) && !userAgent.match(/chrome|firefox|edg|opr/gi) ? 5000 : 0;
    console.log("print delay:  " + afterPrintDelay);
    var currentUrl = window.location.href;
    history.replaceState(history.state, '', '/');
    console.log('url before print ' + currentUrl);
    window.print();
    // before and after print events happen right away on safari
    setTimeout(function () {
        console.log('url after print ' + currentUrl);
        history.pushState(history.state, '', currentUrl);
        history.replaceState(history.state, '', currentUrl);
    }, afterPrintDelay);
};